.neutrals {
    color: rgba(255, 255, 255, 0.85);
}

.h2{
    color: rgba(255, 255, 255, 0.90);
}

.h1 {
    color: rgba(255, 255, 255, 0.95);
}

.white {
    color: rgba(255, 255, 255, 1);
}

.neutrals-lighten-1 {
    color: rgba(255, 255, 255, 0.7);
}

.neutrals-lighten-2 {
    color: rgba(255, 255, 255, 0.6);
}

.easymed-theme {
    color: #9CA5E6;
    opacity: 1;
}