
.projects-div {
    display: flex;
    flex-direction: row;
  }
  
  .hidden {
    display: none;
    visibility: hidden;
  }

  
  .project-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden {
    display: none;
  }

  .project-name {
    margin: 0;
  }
  
  .dev-project-section {
    height: 100vh;
    perspective: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: auto;
  }

  .projects-list::-webkit-scrollbar {
    display: none;
  }

  .projects-list {
    display: flex;
    list-style: none;
    overflow-x: auto;
    column-gap: 2vw;
    padding: 0;
  }


  .project-div:last-of-type {
    margin-right: 4vw;
  }
  
  html {
    scroll-snap-type: y mandatory;
  }

  .left-scroll {
    position: absolute;
    top: 50%;
    left: 0;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-scroll {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chevron-icon {
    width: 32px;
    height: 32px;
  }

  
  
  @media only screen and (max-width: 768px) {
    .image-div {
          width: 50vw;
          aspect-ratio: 3/4;
    }
  
    .dev-project-section {
      flex-direction: column;
      justify-content: center;
    }
  
    .project-name {
      font-size: 1.5rem;
    }
    
    .project-skill {
      font-size: 1rem;
    }
  }