

#app-div {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.cursor {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: #212121;
  z-index: 10000;
}

