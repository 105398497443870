
.projects-div {
  display: flex;
  flex-direction: row;
}

.hidden {
  display: none;
  visibility: hidden;
}

.progress {
  position: fixed;
  left: 2vw;
  right: 2vw;
  bottom: 1rem;
  height: 5px;
  background: #ffffff;
}

.project-image {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  object-fit: cover;
}

.hidden {
  display: none;
}

.scroll-down {
  position: absolute;
  bottom: 2rem;
  text-align: center;
  width: 100%;
}

.project-section {
  display: flex;
  align-items: top;
  justify-content: center;
  position: relative;
  padding-top: 6vh;
}


.intro-header-section {
  height: 100vh;
  display: flex;
  padding-left: 4vw;
  padding-right: 4vw;
  position: relative;
}

.image-div {
  width: 100%;
  aspect-ratio: 1140/700;
  position: relative;
  max-height: 90vh;
  overflow: hidden;
  object-fit: cover;
}


html {
  scroll-snap-type: y mandatory;
}
