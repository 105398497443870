.container {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 72px;
    background-color: #00000077;
    position: sticky;
    top: 0;
    z-index: 1001;
}


.logo-icon {
    width: 44px;
    height: 44px;
    cursor: none;
}

.menu {
    display: none;
}

.menu-icon {
    width: 28px;
    height: 28px;
}

.nav-elements {
    margin-top: auto;
    margin-bottom: auto;
}

ul {
list-style-type: none;
}

a {
text-decoration: none;
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.nav-elements ul li:not(last-child) {
    margin-right: 60px;
}

.nav-elements ul a {
    color: #dddddd;
    font-weight: 400;
    position: relative;
    font-size: 1.125rem;
    font-family: "instagram-sans";
    letter-spacing: 0.02rem;
    cursor: none;
}

.nav-elements ul a.active {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.25rem;
    font-family: "instagram-sans";
    letter-spacing: 0.02rem;
}

@media (max-width:768px) {
    .menu {
        display: block;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #00000077;
        width: 0px;
        height: calc(100vh - 72px);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements.active {
        width: 300px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .nav-elements ul li {
        margin-top: 22px;
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
    }
}