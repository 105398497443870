
button {
  --purple: #ad08a5;
  --pink: #ca0960;
  --blue: #0480a3;

  --moonlit1: #0f2027;
  --moonlit2: #203a43;
  --moonlit3: #2C5364;


  appearance: none;
  border: none;
  cursor: none;
  background-color: #2a2a2a;
  box-shadow: inset -4px -4px 5px #20202088, inset 4px 4px 5px #42424255, 0px 0px 20px #42424299;
  color: #ffffffdd;
  border-radius: 40px;
  outline: none;
  margin: 0;
  font-family: "instagram-sans";
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}

button:hover {
  box-shadow: none;
}

.shapes {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 40px;
  background: linear-gradient(
    60deg,
    var(--blue) 0%,
    #d6cbf6 30%,
    var(--pink) 70%
  );
}

.shapes-dev {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 40px;
  background: linear-gradient(
    60deg,
    var(--moonlit1) 0%,
    var(--moonlit2) 30%,
    var(--moonlit3) 60%
  );
}

.blush {
  position: absolute;
  bottom: -15px;
  width: 100px;
  height: 30px;
  filter: blur(20px);
}

.blush.pink {
  right: 20px;
  background: var(--purple);
}

.blush.moon1 {
  right: 20px;
  background: var(--moonlit2);
}

.blush.moon2 {
  left: 20px;
  background: var(--moonlit3);
}

.blush.blue {
  left: 20px;
  background: var(--blue);
}

.threeD-container {
  position: absolute;
  top: -100px;
  bottom: -100px;
  left: -100px;
  right: -100px;
  width: calc(100% + 200px);
  pointer-events: none;
}

.shapes canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.label {
  width: 240px;
  padding: 12px 0;
  transform: translateZ(0);
  font-weight: 700;
  z-index: 1;
}

.default {
  display: block;
}

.current {
  color: #8a8d9b;
  opacity: 1;
  display: block;
}

.new {
  color: #fbfaaa;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: block;
}

.add {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(38px);
  pointer-events: none;
  color: #d0d0db;
  display: block;
}
