@font-face{
    font-family: 'instagram-sans';
    src : url('Assets/Fonts/Instagram-Sans.ttf');
}

@font-face{
    font-family: 'instagram-sans-headline';
    src : url('Assets/Fonts/Instagram-Sans-Headline.ttf');
}

.display-large {
    font-size: 2.2rem;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0.1rem;
    max-width: 90vw;
    display: block;
    font-family: "instagram-sans";
}

.display-medium {
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0.1rem;
    max-width: 90vw;
    display: block;
    font-family: "instagram-sans";
}

.headline-large {
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.06rem;
    max-width: 90vw;
    display: block;
    font-family: "instagram-sans";
}

.headline-large-bold {
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0.06rem;
    max-width: 90vw;
    font-family: "instagram-sans";
}

.headline-medium {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 500;
    max-width: 90vw;
    display: block;
    letter-spacing: 0.08rem;
    font-family: "instagram-sans";
}

.headline-small {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 500;
    max-width: 90vw;
    display: block;
    letter-spacing: 0.04rem;
    font-family: "instagram-sans";
}

.title-large {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    max-width: 90vw;
    display: block;
    font-family: "instagram-sans";
}

.title-medium {
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 400;
    max-width: 90vw;
    display: block;
    font-family: "instagram-sans";
}

.label-large {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 700;
    max-width: 90vw;
    letter-spacing: 0.04rem;
    font-family: "instagram-sans";
}

.label-medium {
    font-size: 0.875rem;
    line-height: 1.6;
    font-weight: 600;
    max-width: 90vw;
    font-family: "instagram-sans";
}

.label-small {
    font-size: 0.75rem;
    line-height: 1.6;
    font-weight: 600;
    max-width: 90vw;
    font-family: "instagram-sans";
}

.body-large {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    max-width: 90vw;
    font-family: "instagram-sans";
}

.body-medium {
    font-size: 0.875rem;
    line-height: 1.6;
    font-weight: 400;
    max-width: 90vw;
    font-family: "instagram-sans";
}

.body-small {
    font-size: 0.75rem;
    line-height: 1.6;
    font-weight: 400;
    max-width: 90vw;
    font-family: "instagram-sans";
}
