html {
  margin: 0;
}

body {
  --black: #000000;
  --white: #fafafa;
  --bg: #121212;
  --darkGrey: #555555;

  --background: var(--bg);

  margin: 0;
  padding: 0;
  background-color: var(--bg);
  -webkit-tap-highlight-color: transparent;
  cursor: none;
  overflow-x:hidden;

  color: rgba(255, 255, 255, 0.85);
  font-size: 1.2rem;
  letter-spacing: 0.07rem;
  max-width: 90vw;
  display: block;
  font-family: "instagram-sans";
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #212121;
}

::-webkit-scrollbar-thumb {
  background: #fafafa;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #212121;
}

.flex {
  display: flex;
  gap: var(--gap, 2rem);
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
}

